
/*BACKGROUND*/

.bg-gradient-info {
    background: linear-gradient(87deg, #32B195 0, #32B195 100%) !important;
    background-image: linear-gradient(50.26deg, rgb(54, 67, 78) 0%, rgba(0, 215, 166, 0.9) 100%), url(https://saitebooker.com.br/wp-content/themes/saitebooker/images/img_background.jpeg) !important;
}
.bg-gradient-index {
    background-image: linear-gradient(50.26deg, rgb(54, 67, 78) 0%, rgba(0, 215, 166, 0.9) 100%), url(https://saitebooker.com.br/wp-content/themes/saitebooker/images/img_background.jpeg) !important;
}
.showcase {
    background: linear-gradient(50.26deg, #0000FF 0%, #00D7A6 100%);
    color: #FFFFFF;
}
.example{
    color: #1F4963;
    color: #32B195;
    color: #E85C50;
    color: #1a174d;
}
.fill-default {
    fill: #1F4963;
}
.bg-gradient-default {
    background: linear-gradient(87deg, #1F4963 0, #1F4963 100%) !important;
}
.bg-default {
    background-color: #1F4963 !important;
}
.navbar-horizontal .navbar-brand img {
    height: 100px;
}
body {
    margin: 0;
    font-family: Open Sans, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #525f7f;
    text-align: left;
    background-color: #9ac5e0 !important;
}.footer {
    background: #9ac5e0;
    padding: 2.5rem 0;
}

@media (min-width: 768px){
    .navbar-vertical.navbar-expand-md .navbar-brand-img {
        max-height: 5.5rem;
    }
}

/*BTN*/
.btn-info {
    color: #fff;
    background-color: #32B195;
    border-color: #1d6857;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.btn-info:hover {
    color: #fff;
    background-color: #32B195;
    border-color: #1d6857;
}
.btn-info:active {
    color: #fff;
    background-color: #32B195;
    border-color: #1d6857;
}
.btn-danger {
    color: #fff;
    background-color: #E85C50;
    border-color: #b63c31;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.btn-danger:hover {
    color: #fff;
    background-color: #E85C50;
    border-color: #b63c31;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.btn-danger:active {
    color: #fff;
    background-color: #E85C50;
    border-color: #b63c31;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}


/*TABLE*/
.table-dark {
    color: #f8f9fe;
    background-color: #1F4963;
}
table .thead-dark th {
    color: #f8f9fe;
    background-color: #0b4061;
    border-color: #0b4061;
}
.table .thead-dark th {
    background-color: #012e4a;
    color: #fff;
}

/*FORMS*/
.form-control {
    color: #000 !important;
}


/* Inactive tab */
.myNav ul > li > a {
    background-color: #5e72e4;
    color: white;
}
.myNav ul > li > a:hover {
    background-color: #5e72e4;
}
.myNav ul > li > a:focus {
    background-color: #3550e9;
    color: white;
}
.myNav ul > li > a:hover {
    background-color: #3550e9;
    color: white;
}

/* Active tab 
.myNav ul > li.active > a,
.myNav ul > li.active > a:hover,
.myNav ul > li.active > a:after,
.myNav ul > li.active > a:focus {
    background-color: red;
    color: white;
}*/


/* Disabled tab 
.myNav ul > li.disabled > a {
    background-color: lightgray;
    color: darkgray;
}
*/

.banner {
    background-image: url("../../assets/img/brand/gamequiz material-20.png");
    background-size: cover;
    background-repeat: no-repeat;
    
}
